<template>
  <div class>
    <div class="d-flex mb-2">
      <span class="page-header-title">Product Category List</span>
    </div>
    <CCard>
      <CCardBody>
        <div class="d-md-flex d-sm-flex justify-content-end mt-3">
          <div>
            <b-input-group class="mb-2">
              <template #append>
                <b-input-group-text @click="onSearch(searchText)">
                  <CIcon name="cil-search" class="cicon cursor" />
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="searchText"
                placeholder="Category Name"
                v-on:keyup.enter="onSearch(searchText)"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="mr-0">
            <b-button
              variant="primary"
              class="float-right ml-3 mt-3 mt-lg-0 mt-md-0 mt-sm-0"
              @click="showAddModal()"
            >
              <em class="fa fa-plus" aria-hidden="true"></em>
              Add New
            </b-button>
          </div>
        </div>

        <div class="table-responsive mt-2">
          <CustomTableUser
            class="vertical-align-middle table-responsive account_table"
            :items="categoryList"
            hover
            striped
            border
            :small="'small'"
            fixed
            caption
            :fields="fields"
            :per-page="perPage"
            :total="total"
            :loadTableData="loadTableData"
            :rowSelectHandler="rowClickHandler"
            :onRowDeleteHandler="deleteTableData"
            :onRowEditHandler="editTableData"
            :rowFliterHandler="rowFliterHandler"
            :currentPagination="currentPageOfManagers"
            :onClickImage="showImage"
          />
        </div>
      </CCardBody>
    </CCard>
    <!-- Add New Category Modal -->
    <b-modal id="add-category-modal" title="Add New Category" size="lg">
      <b-form @submit.prevent="addCategory">
        <label class="asterisk font-weight-bold">Name </label>
        <p class="notification-input">
          <CInput
            placeholder="name"
            v-model="newCategory.category_name"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.newCategory.category_name"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Description </label>
        <p class="">
            <CTextarea aria-rowcount="3"  required
            was-validated v-model="newCategory.description"></CTextarea>
          <show-errors
            class=""
            :control="$v.newCategory.description"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 font-weight-bold asterisk"> Image Upload </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileupload"
              ref="imageUploader"
              type="file"
              hidden
              @change="onFileChange"
              accept="image/png, image/jpeg"
            />
          </label>
          <div v-if="fileName" class="ml-2 mb-2">
            {{ fileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(index)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{ fileErrorMessage }}</span>
        </div>
      </b-form>
      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="onCancelAdd()"
              >Cancel
            </b-button>
            <b-button
              size="md"
              variant="primary"
              class="ml-2 px-2"
              @click="addCategory()"
              >Create
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Edit Category Modal -->
    <b-modal id="edit-category-modal" title="Category Edit" size="lg">
      <b-form @submit.prevent="editCategory">
        <label class="asterisk font-weight-bold">Name </label>
        <p class="notification-input">
          <CInput
            placeholder="name"
            v-model="selectedCategory.category_name"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.selectedCategory.category_name"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Description </label>
        <p class="">
          <CTextarea aria-rowcount="3"  required
            was-validated v-model="selectedCategory.description"></CTextarea>
          <show-errors
            class=""
            :control="$v.selectedCategory.description"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>

        <label class="mt-2 font-weight-bold asterisk"> Image Upload </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileupload"
              ref="imageUploader"
              type="file"
              hidden
              @change="onFileChange"
              accept="image/png, image/jpeg"
            />
          </label>
          <div v-if="fileName" class="ml-2 mb-2">
            {{ fileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(index)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{ fileErrorMessage }}</span>
        </div>
      </b-form>
      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="onCancelEdit()"
              >Cancel
            </b-button>
            <b-button
              size="md"
              variant="primary"
              class="ml-2 px-2"
              @click="editCategory()"
              >Save
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Category Details Modal -->
    <b-modal
      id="details-category-modal"
      title="Category Details"
      size="lg"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Name</div>
        <div class="col-lg-10 mt-2 pt-1">
          {{ selectedCategory.category_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Description</div>
        <div class="col-lg-10 mt-2 pt-1">
          {{ selectedCategory.description||"-" }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Image</div>
        <div class="col-lg-10 mt-2 pt-1">
          <span v-if="selectedCategory.image">
          <img :src=selectedCategory.image width="80" height="80px" class="mt-1" />
        </span>
        <span v-else> - </span>
        </div>
      </div>
    </b-modal>

    <!-- Confirm Delete Modal -->
    <b-modal
      id="confirm-delete-category"
      hide-header
      class="cancelbtn"
      title="Delete Data"
      ok-title="Yes"
      cancel-title="Cancel"
      @ok="deleteCategory"
    >
      <p class="my-4 wrap">Are you sure you want to delete this category?</p>
    </b-modal>
    <b-modal id="image-modal" hide-footer title="Image Preview">
      <template v-slot:default>
        <div class="text-center">
          <img :src="selectedImageUrl" alt="Preview" class="img-fluid" />
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { multipleErrorMessages, senitizeMtbName,sanitizedDescription } from "@/shared/utils";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import CustomTableUser from "@/components/Table/TableUser.vue";
import {
  PRODUCT_CATEGORY_CREATE,
  PRODUCT_CATEGORY_DELETE,
  PRODUCT_CATEGORY_DETAILS,
  PRODUCT_CATEGORY_LIST,
  PRODUCT_CATEGORY_UPDATE,
} from "../../store/actions.type";
export default {
  name: "ProductCategoryList",
  components: { CustomTableUser, ShowErrors },

  /**
   * Returns an object containing the initial data for the Vue component.
   *
   * @return {Object} An object with the initial data for the component
   */
  data() {
    return {
      categoriesData: [{ value: "", text: "Select Category" }],
      categoryList: [],
      newCategory: { category_name: "" },
      fileName: "",
      fileErrorMessage: "",
      files: [],
      searchText: "",
      selectedCategory: { category_name: "",description:"",image:"" },
      selectedImageUrl: "",
      deleteSelCategory: "",
      productFilter: {
        filters: {
          start: 0,
          limit: 50,
          sort_by: "",
          order_by: "",
        },
        category_name: "",
      },
      perPage: 50,
      total: 0,
      currentPage: 1,
      pageStart: 0,
      sortField: "created_at",
      sortDirection: "desc",
      currentPageOfManagers: 1,

      fields: [
        {
          key: "category_name",
          label: "Category Name",
          _classes: "category_name tbl-width-100",
          sortable: true,
        },
        // { key: "description", label: "Description",_classes: "category_description", sortable: true },
        {
          key: "image",
          label: "Image",
          sorter: false,
          _classes: "tbl-width-50",
        },
        // { key: "url", label: "URL", sorter: false,_classes: "category_url",  },
        {
          key: "productAction",
          label: "Action",
          sortable: false,
          _classes: "tbl-width-5",
        },
      ],
    };
  },

  validations: {
    newCategory: {
      category_name: { required, senitizeMtbName, maxLength: maxLength(100) },
      description: { required, sanitizedDescription, maxLength: maxLength(1000) },
    },
    selectedCategory: {
      category_name: { required, senitizeMtbName, maxLength: maxLength(100) },
      description: { required, sanitizedDescription, maxLength: maxLength(1000) },
    },
  },

  methods: {
    /**
     * @description function to check and validate browsed files
     * @param {file} data
     */
    onFileChange(event) {
      let fileSize = 0;
      for (const element of event.target.files) {
        var ext = element.type;
        fileSize += Math.round(element.size / (1024 * 5));
        if (ext == "image/png" || ext == "image/jpeg") {
          if (fileSize > 1024) {
            this.fileErrorMessage = "Please upload less than 5 mb";
          } else {
            for (let i = 0; i < event.target.files.length; i++) {
              this.files.push(event.target.files[i]);
            }

            this.fileName = event.target.files[0].name;
            this.fileErrorMessage = "";
          }
        } else {
          this.fileErrorMessage =
            "Please upload valid image file (png, jpeg)";
        }
        fileSize += Math.round(element.size / (1024 * 5));
      }
    },

    /**
     * Deletes a file from the list of files.
     *
     * @param {number} index - The index of the file to delete.
     * @return {void}
     */
    deleteFile(index) {
      this.fileErrorMessage = "";
      if (this.files.length < 1) {
        this.fileName = "";
        this.deleteImage = true;
      } else {
        this.files.splice(index, 1);
        this.fileName = "";
      }
      this.$refs.imageUploader.value = ""; // Reset the file input
    },

    /**
     * Displays the selected image in a modal.
     *
     * @param {Object} item - The item containing the image URL.
     * @return {void}
     */
    showImage(item) {
      this.selectedImageUrl = item.image;
      this.$bvModal.show("image-modal");
    },

    /**
     * Hides the "image-modal" modal.
     *
     * @return {void}
     */
    hideModal() {
      this.$bvModal.hide("image-modal");
    },

    /**
     * Updates the product filter with the provided text and fetches categories.
     *
     * @param {string} text - The text to update the product filter with.
     * @return {void} This function does not return anything.
     */
    onSearch(text) {
      this.productFilter.category_name = text;
      this.fetchCategories();
    },

    /**
     * Updates the selected category and fetches the categories.
     *
     * @param {Object} category - The category object to be set as the selected category.
     * @return {Promise} A promise that resolves when the categories are fetched.
     */
    filterCategories(category) {
      this.selectedCategory = category;
      this.fetchCategories();
    },

    /**
     * Asynchronously fetches product categories based on the current page, per page, sort field, sort direction, and product filter.
     *
     * @return {Promise<void>} A promise that resolves when the categories are fetched.
     */
    async fetchCategories() {
      this.productFilter.filters.start =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.productFilter.filters.limit = this.perPage;
      this.productFilter.filters.sort_by = this.sortField;
      this.productFilter.filters.order_by = this.sortDirection;
      this.isLoadingFetchProduct = this.$loading.show();
      let data = await this.$store.dispatch(
        PRODUCT_CATEGORY_LIST,
        this.productFilter
      );
      this.isLoadingFetchProduct.hide();
      if (_.get(data, "success", true)) {
        this.categoryList = _.get(data, "list", []);
        this.total = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.categoryList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * Updates the current page, page start, per page, and current page of promotions
     * and calls the fetchPromotionList method.
     *
     * @param {number} currentPage - The current page number.
     * @param {number} limit - The number of items per page.
     * @return {void} This function does not return anything.
     */
    loadTableData(currentPage, limit) {
      this.currentPage = currentPage;
      this.pageStart =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.perPage = limit;
      this.currentPageOfPromotions = currentPage;
      this.fetchPromotionList();
    },

    /**
     * Deletes the table data.
     *
     * @param {Object} item - The item to be deleted.
     * @return {void}
     */
    deleteTableData(item) {
      this.confirmDelete(item);
    },

    /**
     * @method rowFliterHandler
     * @description Method to load table data with filter
     */
    async rowFliterHandler(e) {
      this.sortDirection = e.asc ? "asc" : "desc";
      this.sortField = e.column;
      this.fetchCategories();
    },

    /**
     * @method editTableData
     * @description redirects edit page
     */
    editTableData(item) {
      this.showEditModal(item);
    },

    /**
     * Resets the form fields and shows the modal for adding a new category.
     *
     * @return {void}
     */
    showAddModal() {
      this.$v.newCategory.$reset();
      this.newCategory = { category_name: "" };
      this.files = [];
      this.fileErrorMessage = "";
      this.fileName = "";
      this.$bvModal.show("add-category-modal");
    },

    /**
     * Asynchronously adds a new category to the product category list.
     *
     * @return {Promise<void>} A promise that resolves when the category is added successfully.
     */
    async addCategory() {
      this.$v.$touch();
      if (this.files.length <= 0 && this.fileName == "") {
        this.fileErrorMessage = "Please upload file.";
        return;
      }
      if (!this.$v.newCategory.$invalid) {
        let formData = new FormData();
        formData.append("category_name", this.newCategory.category_name);
        formData.append("description", this.newCategory.description);
        this.files[0] && formData.append("image", this.files[0]);
        this.fileErrorMessage = "";
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store
          .dispatch(PRODUCT_CATEGORY_CREATE, formData)
          .then((data) => {
            if (_.get(data, "data.success", false)) {
              this.files = [];
              this.fileName = "";
              this.isLoadingSubmitNotify.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.$bvModal.hide("add-category-modal");
              this.$v.newCategory.$reset();
              this.fetchCategories();
            } else {
              this.files = [];
              this.fileName = "";
              this.$v.newCategory.$reset();
              this.$bvModal.hide("add-category-modal");
              this.isLoadingSubmitNotify.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
      }
    },

    /**
     * Initializes the edit category modal and sets the selected category and file error message.
     *
     * @param {Object} item - The category item to be edited.
     * @return {void}
     */
    showEditModal(item) {
      this.$v.selectedCategory.$reset();
      this.selectedCategory = { ...item };
      this.fileErrorMessage = "";
      let path = item.image || "";
      if (path) {
        const parts = path.split("/");
        // The last element in the array will be the filename
        this.fileName = parts[parts.length - 1];
      } else {
        this.fileName = "";
      }

      this.$bvModal.show("edit-category-modal");
    },

    /**
     * Resets the selected category to an empty object and hides the edit category modal.
     *
     * @return {void}
     */
    onCancelEdit() {
      this.selectedCategory = { category_name: "",description:"" };
      this.$bvModal.hide("edit-category-modal");
    },

    /**
     * Hides the add category modal.
     *
     * @return {void}
     */
    onCancelAdd() {
      this.$bvModal.hide("add-category-modal");
    },

    /**
     * Handles the click event on a row.
     *
     * @param {Object} item - The item object representing the row.
     * @return {Promise<void>} A promise that resolves when the function completes.
     */
     async rowClickHandler(item) {
      this.isLoadingRow = this.$loading.show();
      if (item.id) {
        let data = await this.$store.dispatch(PRODUCT_CATEGORY_DETAILS, {
          id: item.id,
        });
        this.selectedCategory.category_name = data.category_name || "";
        this.selectedCategory.description = data.description || "";
        this.selectedCategory.image = data.image || "";
        this.isLoadingRow.hide();
        this.showDetailsModal();
      }
      this.isLoadingRow.hide();
    },

     /**
     * Show the details modal for the category.
     *
     * @return {void} No return value
     */
     showDetailsModal() {
      this.$bvModal.show("details-category-modal");
    },

    /**
     * Asynchronously edits a category. Performs validation on the selected category object,
     * creates a FormData object with the necessary data, and dispatches a PRODUCT_CATEGORY_UPDATE
     * action to update the category in the store. If the category is successfully updated,
     * resets the files and fileName variables, hides the loading spinner, displays a success
     * toast message, and refreshes the categories. If there is an error, displays an error
     * toast message with the appropriate message.
     *
     * @return {Promise<void>} A Promise that resolves when the category is successfully updated.
     */
    async editCategory() {
      // Edit category logic here
      this.$v.$touch();
      if (this.files.length <= 0 && this.fileName == "") {
        this.fileErrorMessage = "Please upload file.";
        return;
      }
      if (!this.$v.selectedCategory.$invalid) {
        let formData = new FormData();
        formData.append("id", this.selectedCategory.id);
        formData.append("category_name", this.selectedCategory.category_name);
        formData.append("description", this.selectedCategory.description);
        this.files[0] && formData.append("image", this.files[0]);
        if (
          this.selectedCategory.image != "" &&
          this.selectedCategory.image != null &&
          this.fileName == ""
        ) {
          formData.append("isDelete", 1);
        }
        this.fileErrorMessage = "";
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store
          .dispatch(PRODUCT_CATEGORY_UPDATE, formData)
          .then((data) => {
            if (_.get(data, "data.success", false)) {
              this.files = [];
              this.fileName = "";
              this.isLoadingSubmitNotify.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.$bvModal.hide("edit-category-modal");
              this.$v.selectedCategory.$reset();
              this.fetchCategories();
            } else {
              this.files = [];
              this.fileName = "";
              this.$v.selectedCategory.$reset();
              this.$bvModal.hide("edit-category-modal");
              this.isLoadingSubmitNotify.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
      }
    },

    /**
     * Confirm the deletion of an item.
     *
     * @param {Object} item - The item to be deleted.
     * @return {void} This function does not return a value.
     */
    confirmDelete(item) {
      this.deleteSelCategory = item;
      this.$bvModal.show("confirm-delete-category");
    },

    /**
     * Deletes a category asynchronously.
     *
     * @return {Promise<void>} - A promise that resolves when the category is deleted.
     */
    async deleteCategory() {
      if (this.deleteSelCategory.id) {
        let data = await this.$store.dispatch(PRODUCT_CATEGORY_DELETE, {
          id: this.deleteSelCategory.id,
        });
        if (_.get(data.data, "success", false)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          if (this.categoryList.length == 1) {
            this.pageStart = 0;
            this.currentPage = 1;
            this.perPage = 50;
          }

          this.fetchCategories();
        } else {
          let message = _.get(data, "data.message", "");
          if (message.length > 0) {
            this.$toast.error({
              message: message,
            });
          } else {
            this.$toast.removeAll();
            let messageObj = _.get(data, "data.message", "");
            multipleErrorMessages(this.$toast, messageObj);
          }
        }
      }
      // Delete category logic here
      this.$bvModal.hide("confirm-delete-category");
    },
  },
  created() {
    this.fetchCategories();
  },
};
</script>
<style>
.category_name {
  width: 200px;
}
.category_description {
  width: 200px;
}
.category_url {
  width: 400px;
  min-width: 200px;
}
.ql-editing {
  left: 1px !important;
}

.ql-container .ql-editor {
  min-height: 225px !important;
}
.content-manage {
  padding: 0px !important;
}

.quill-text {
  min-height: 225px !important;
}

.ql-editor {
  min-height: 225px !important;
}

.wrap {
  word-break: break-word;
}
.img {
  /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */
  /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
  -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
  -webkit-user-select: none;
  -ms-user-select: none; /* From IE10 only */
  user-select: none; /* Not valid CSS yet, as of July 2012 */

  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
}
</style>
